// src/pages/MenuPage.jsx
import React, { useLayoutEffect } from 'react';
import Menu from '../components/Menu'; // Importing the Menu component
import TopPage from '../components/TopPage';
import CartSidebar from '../components/CartSideBar';

const MenuPage = ({ cart, addToCart, toggleCart, showCart, removeFromCart }) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Vergeet niet om de dependency array toe te voegen!

    return (
        <div>
            <TopPage pageTitle={'Ons menu'} />
            <Menu cart={cart} addToCart={addToCart} toggleCart={toggleCart} />
            <CartSidebar showCart={showCart} toggleCart={toggleCart} cart={cart} removeFromCart={removeFromCart} />
        </div>
    );
};

export default MenuPage;
