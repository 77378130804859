import React from 'react';

const TopPage = ({ pageTitle }) => {
    const backgroundStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/pizza3.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative', // Ensure positioning context for absolute positioning
        paddingTop: '100px', // Add padding to ensure content is visible
        paddingBottom: '100px',
      };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.15)', // Black overlay with 0.5 opacity
        zIndex: 1, // Ensure the overlay appears above the background image
    };

    const textStyle = {
        position: 'relative', // Ensure text stays above the overlay
        color: 'white', // Set text color to white
        zIndex: 2, // Ensure text appears above the overlay
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)', // Add text shadow effect
        // You can adjust the shadow values (offset-x, offset-y, blur-radius, color) as needed
    };

  return (
    <div style={backgroundStyle} className="text-center">
      <div style={overlayStyle}></div>
      <h1 style={textStyle}>{pageTitle}</h1>
    </div>
  );
};

export default TopPage;
