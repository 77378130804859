import React from 'react';
import { Container, Row, Col, Card} from 'react-bootstrap';
import Map from '../components/Map';
import OpeningTimes from '../components/OpeningTimes';
import Address from '../components/Address';
import CartSidebar from '../components/CartSideBar';

const AboutUs = ({ toggleCart, showCart, cart , removeFromCart}) => {
  // De state voor de cart wordt niet meer lokaal beheerd in AboutUs
  // Hier gebruiken we gewoon de cart die we als prop krijgen

  return (
    <div>
      <div className="about-us-container">
        <Container>
          <h1>Over ons</h1>
          <Row>
            <Col md={6}>
              <OpeningTimes />
            </Col>
            <Col md={6}>
              <Address />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Locatie</Card.Title>
                  <Map />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CartSidebar showCart={showCart} toggleCart={toggleCart} cart={cart} removeFromCart={removeFromCart} />
    </div>
  );
};

export default AboutUs;