import { Container, Card, Button } from 'react-bootstrap';
import { useState, useEffect, useRef, useCallback } from 'react';

const Menu = ({ cart, addToCart, toggleCart }) => {
  const [products, setProducts] = useState([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [activeCategory, setActiveCategory] = useState('');
  const categoryContainerRef = useRef(null);
  const buttonRefs = useRef({}); // Reference to each category button

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://web-production-1f038.up.railway.app/api/menu');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        const formattedProducts = data.map((product,) => ({
          id: product.product_id,
          category: product.category,
          name: product.name,
          price: product.price,
          description: product.description,
        }));
        setProducts(formattedProducts);

        // Set the initial active category after products are fetched
        const initialCategory = formattedProducts[0]?.category || '';
        setActiveCategory(initialCategory);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, []);

  const scrollToActiveCategory = useCallback(() => {
    const activeButton = buttonRefs.current[activeCategory];
    const categoryContainer = categoryContainerRef.current;
    if (activeButton && categoryContainer) {
      const containerRect = categoryContainer.getBoundingClientRect();
      const buttonRect = activeButton.getBoundingClientRect();

      // Calculate the scroll position to center the active button
      categoryContainer.scrollLeft += buttonRect.left - containerRect.left - containerRect.width / 2 + buttonRect.width / 2;
    }
  }, [activeCategory]);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 300) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }

    const categories = getUniqueCategories();
    let currentCategory = '';

    categories.forEach((category) => {
      const categoryElement = document.getElementById(category);
      if (categoryElement) {
        const rect = categoryElement.getBoundingClientRect();
        if (rect.top <= 60 + 100 && rect.bottom >= 60 + 100) {
          currentCategory = category;
        }
      }
    });

    if (currentCategory !== activeCategory) {
      setActiveCategory(currentCategory);
      scrollToActiveCategory();
    }
  }, [activeCategory, scrollToActiveCategory]);

  const getUniqueCategories = useCallback(() => {
    const categories = products.map((item) => item.category);
    return [...new Set(categories)];
  }, [products]); // Include 'products' as a dependency

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    // Scroll to the active category after it is set initially
    if (activeCategory) {
      scrollToActiveCategory();
    }
  }, [activeCategory, products, scrollToActiveCategory]);

  const scrollToCategory = (category) => {
    const categoryElement = document.getElementById(category);
    if (categoryElement) {
      const offset = 100; // Height of the sticky navigation
      const elementPosition = categoryElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      setActiveCategory(category);
    }
  };

  const toggleCartButtonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: showScrollToTop ? 'block' : 'none',
    zIndex: 100,
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px',
    cursor: 'pointer',
  };

  const categoryContainerStyle = {
    display: 'flex',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 10,
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  };

  const hideScrollbar = {
    '&::WebkitScrollbar': {
      display: 'none',
    },
  };

  return (
    <Container className='mt-3'>
      <div style={{ ...categoryContainerStyle, ...hideScrollbar }} ref={categoryContainerRef}>
        {getUniqueCategories().map((category) => (
          <Button
            key={category}
            ref={(el) => (buttonRefs.current[category] = el)}
            variant={activeCategory === category ? 'primary' : 'light'}
            onClick={() => scrollToCategory(category)}
            style={{ marginRight: '10px' }}
            aria-label={`Scroll to ${category}`}
          >
            {category}
          </Button>
        ))}
      </div>
      {getUniqueCategories().map((category) => (
        <Card key={category} className="mb-3">
          <Card.Header>{category}</Card.Header>
          <Card.Img
            style={{ maxHeight: '150px', width:'100%',objectFit: 'cover', objectPosition: 'center' }}
            variant="top"
            src={process.env.PUBLIC_URL + '/images/' + category + '.png'}
            alt={`${category} afbeelding`}
            loading="lazy"
          />        
          <Card.Body id={category}>
            {products
              .filter((item) => item.category === category)
              .map((item) => (
                <Card key={item.id} className="mb-3">
                  <Card.Body>
                    <h4>{item.name}</h4>
                    <p>{item.description}</p>
                    <p>Prijs: €{item.price.toFixed(2)}</p>
                    <Button variant="success" onClick={() => addToCart(item)}>
                      Bestel nu
                    </Button>
                  </Card.Body>
                </Card>
              ))}
          </Card.Body>
        </Card>
      ))}
      <Button onClick={toggleCart} style={toggleCartButtonStyle}>
        Winkelwagen
      </Button>
    </Container>
  );
};

export default Menu;
