import React, { useState, useEffect } from 'react';

const ProductModal = ({ showModal, handleCloseModal, selectedProduct, onConfirm }) => {
  const [options, setOptions] = useState({}); // State for product options
  const [selectedOptions, setSelectedOptions] = useState({}); // State to hold selected option values
  const [totalPrice, setTotalPrice] = useState(Number(selectedProduct?.price) || 0); // Ensure totalPrice is a number

  useEffect(() => {
    if (showModal && selectedProduct) {
      // Fetch options from the backend when the modal is opened
      fetch(`https://web-production-1f038.up.railway.app/api/products/${selectedProduct.id}/options`)
        .then(response => response.json())
        .then(data => {
          setOptions(data); // Store fetched options
        })
        .catch(error => console.error('Error fetching product options:', error));

      // Reset selected options and total price when modal opens
      setSelectedOptions({});
      setTotalPrice(Number(selectedProduct?.price) || 0);
    }
  }, [showModal, selectedProduct]);

  const handleOptionChange = (event, optionKey) => {
    const value = event.target.value;
    setSelectedOptions(prevState => {
      const updatedOptions = {
        ...prevState,
        [optionKey]: value,
      };

      // Calculate the new price based on selected options
      let newTotalPrice = Number(selectedProduct.price);

      Object.keys(updatedOptions).forEach(option => {
        const selectedValue = updatedOptions[option];
        const optionDetail = options[option]?.find(opt => opt.value_name === selectedValue);
        if (optionDetail) {
          newTotalPrice += Number(optionDetail.price_increase) || 0;
        }
      });

      setTotalPrice(newTotalPrice); // Update the total price as a number
      return updatedOptions;
    });
  };

  const handleConfirm = () => {
    // Create an object with the selected options and final price

    const productWithOptions = {
      ...selectedProduct,
      options: selectedOptions,
    };
    console.log("Confirmed product with options:", productWithOptions);
    onConfirm(productWithOptions, totalPrice); // Pass the product with selected options and total price to the parent
  };

  if (!showModal) {
    return null; // Do not render if modal is not shown
  }

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <button style={styles.closeButton} onClick={handleCloseModal}>
          &times;
        </button>
        <h2>{selectedProduct?.name}</h2>
        <p>{selectedProduct?.description}</p>
        <p>Prijs: €{totalPrice.toFixed(2)}</p>

        {Object.keys(options).length > 0 ? (
          <div style={styles.optionsContainer}>
            {Object.keys(options).map(optionKey => (
              <div key={optionKey} style={styles.option}>
                <h3 htmlFor={optionKey}>Kies {optionKey}:</h3>
                <select
                  id={optionKey}
                  onChange={(event) => handleOptionChange(event, optionKey)}
                  value={selectedOptions[optionKey] || ""} // Controlled component
                >
                  <option value="">Kies</option>
                  {options[optionKey].map((value, index) => (
                    <option key={index} value={value.value_name}>
                      {value.value_name} {value.price_increase ? `(+€${value.price_increase})` : ''}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        ) : (
          <p>Geen keuze mogelijk</p>
        )}

        <div style={styles.modalActions}>
          <button onClick={handleConfirm}>Voeg toe</button>
          <button onClick={handleCloseModal}>Annuleer</button>
        </div>
      </div>
    </div>
  );
};

// Styles object
const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '20px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  optionsContainer: {
    margin: '20px 0',
  },
  option: {
    marginBottom: '10px',
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default ProductModal;
