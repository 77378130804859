import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Select,
  MenuItem,
} from '@mui/material';
import { useRef } from 'react';

const Dashboard = () => {
  const [todayOrders, setTodayOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [dateOrders, setDateOrders] = useState([]);
  const [lastFetched, setLastFetched] = useState(Date.now());
  const [newOrders, setNewOrders] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [notificationVisible, setNotificationVisible] = useState(false);

  const intervalRef = useRef(null);
  const abortControllerRef = useRef(null);
  
  const orderStatuses = ['In behandeling', 'Verzonden', 'Afgerond'];

  useEffect(() => {
    const requestNotificationPermission = async () => {
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission();
      }
    };
    requestNotificationPermission();
  }, []);

  useEffect(() => {
    const fetchTodayOrders = async () => {
      try {
        const response = await fetch('https://web-production-1f038.up.railway.app/api/orders/today');
        const data = await response.json();
        setTodayOrders(data);
      } catch (error) {
        console.error('Fout bij het ophalen van vandaag’s bestellingen:', error);
      }
    };

    fetchTodayOrders();
  }, []);

  const fetchOrdersByDate = async () => {
    try {
      const response = await fetch(`https://web-production-1f038.up.railway.app/api/orders/by-date?date=${selectedDate}`);
      const data = await response.json();
      setDateOrders(data);
    } catch (error) {
      console.error('Fout bij het ophalen van bestellingen op datum:', error);
    }
  };

  useEffect(() => {
    const fetchNewOrders = async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      try {
        const response = await fetch(
          `https://web-production-1f038.up.railway.app/api/orders/new?lastFetched=${lastFetched / 1000}`, 
          { signal: abortControllerRef.current.signal }
        );
        const data = await response.json();
        if (data.length > 0) {
          setNewOrders(prev => [...prev, ...data]);
          setLastFetched(Date.now());
          notifyUser();
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Fout bij het ophalen van nieuwe bestellingen:', error);
        }
      }
    };

    intervalRef.current = setInterval(fetchNewOrders, 10000);

    return () => {
      clearInterval(intervalRef.current);
      if (abortControllerRef.current) abortControllerRef.current.abort();
    };
  }, [lastFetched]);

  const acceptOrder = (order) => {
    setTodayOrders(prevOrders => [order, ...prevOrders]);
    setNewOrders(prev => prev.filter(o => o.id !== order.id));
    setNotificationVisible(false);
  };

  const changeOrderStatus = async (orderId, newStatus) => {
    setTodayOrders(prevOrders =>
      prevOrders.map(order =>
        order.id === orderId ? { ...order, status: newStatus } : order
      )
    );
  
    try {
      const response = await fetch(`https://web-production-1f038.up.railway.app/api/orders/${orderId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });
  
      if (!response.ok) {
        throw new Error('Fout bij het bijwerken van de orderstatus');
      }
    } catch (error) {
      console.error('Error updating order status:', error);
    }
    };
  

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const notifyUser = () => {
    const audio = new Audio('/sounds/notification-sound.mp3');
    audio.play().then(() => {
      console.log('Geluid succesvol afgespeeld.');
    }).catch(error => {
      console.error('Fout bij het afspelen van geluid:', error);
    });

    if (Notification.permission === 'granted') {
      const notification = new Notification('Nieuwe Bestelling Ontvangen', {
        body: 'Je hebt nieuwe bestellingen om te bekijken!',
      });
      notification.onclick = () => {
        console.log('Notificatie geklikt!');
      };
    } else {
      console.log('Notificatie toestemming niet verleend');
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <AppBar position="static">
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Bestellingen van vandaag" />
          <Tab label="Bestellingen per Datum" />
        </Tabs>
      </AppBar>

      <Snackbar
        open={notificationVisible}
        onClose={() => setNotificationVisible(false)}
        message={`Je hebt nieuwe bestellingen!`}
        autoHideDuration={6000}
      />

      {newOrders.length > 0 && (
        <Paper sx={{ padding: 2, marginBottom: 2, backgroundColor: '#e0f7fa' }}>
          <Typography variant="h6">Nieuwe Bestellingen</Typography>
          {newOrders.map((order,i) => (
            <Box key={order.id + 1} sx={{ marginBottom: 1 }}>
              <Typography>Bestelling ID: {order.id}</Typography>
              <Button variant="contained" color="primary" onClick={() => acceptOrder(order)}>Accepteer</Button>
            </Box>
          ))}
        </Paper>
      )}

      {activeTab === 0 && (
        <>
          <Typography variant="h5" sx={{ marginTop: 2 }}>Bestellingen van vandaag </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bestelling ID</TableCell>
                  <TableCell>Totaal Prijs</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Klant Informatie</TableCell>
                  <TableCell>Adres</TableCell>
                  <TableCell>Winkelmand Items</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {todayOrders.map((order,i) => (
                  <TableRow key={order.id + 1}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>€{order.total_price.toFixed(2)}</TableCell>
                    <TableCell>{order.payment_status}</TableCell>
                    <TableCell>{order.name}, {order.phone_number}, {order.email}</TableCell>
                    <TableCell>{order.street}, {order.house_number}, {order.postcode}</TableCell>
                    <TableCell>
                      <ul>
                        {order.cart_items.map((item,i) => (
                          <li key={item.id + i}>
                            {item.name} - prijs: €{item.price.toFixed(2)}
                            {item.options && Object.keys(item.options).length > 0 ? (
                              <span> - opties:
                                <ul>
                                  {Object.entries(item.options).map(([key, value],i) => (
                                    <li key={key + i}>
                                      {key}: {typeof value === 'object' ? JSON.stringify(value) : value}
                                    </li>
                                  ))}
                                </ul>
                              </span>
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell>
                      <Select
                        value={order.status || 'In behandeling'}
                        onChange={(e) => changeOrderStatus(order.id, e.target.value)}
                        displayEmpty
                      >
                        {orderStatuses.map(status => (
                          <MenuItem key={status} value={status}>{status}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {activeTab === 1 && (
        <>
          <Typography variant="h5" sx={{ marginTop: 2 }}>Bestellingen per Datum</Typography>
          <input
            type="date"
            value={selectedDate}
            onChange={e => setSelectedDate(e.target.value)}
          />
          <Button variant="contained" onClick={fetchOrdersByDate}>Haal Bestellingen Op</Button>
          {dateOrders.length > 0 && (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Bestelling ID</TableCell>
                    <TableCell>Totaal Prijs</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Klant Informatie</TableCell>
                    <TableCell>Adres</TableCell>
                    <TableCell>Winkelmand Items</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dateOrders.map((order,i) => (
                    <TableRow key={order.id + i}>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>€{order.total_price.toFixed(2)}</TableCell>
                      <TableCell>{order.payment_status}</TableCell>
                      <TableCell>{order.name}, {order.phone_number}, {order.email}</TableCell>
                      <TableCell>{order.street}, {order.house_number}, {order.postcode}</TableCell>
                      <TableCell>
                        <ul>
                          {order.cart_items.map((item,i) => (
                            <li key={item.id + i}>
                              {item.name} - prijs: €{item.price.toFixed(2)}
                              {item.options && Object.keys(item.options).length > 0 ? (
                                <span> - opties:
                                  <ul>
                                    {Object.entries(item.options).map(([key, value],i) => (
                                      <li key={key + i}>
                                        {key}: {typeof value === 'object' ? JSON.stringify(value) : value}
                                      </li>
                                    ))}
                                  </ul>
                                </span>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                      </TableCell>
                      <TableCell>
                        {order.status || 'In behandeling'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Box>
  );
};

export default Dashboard;
