import React from 'react';

export default function Map() {
  return (
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3938.845306605993!2d5.0700367!3d51.5473253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6be45fc4634c5%3A0xc383e03732d81025!2sAlmeria!5e0!3m2!1sen!2sus!4v1644178266197!5m2!1sen!2sus'
          width="100%"
          height="100%"
          allowFullScreen
          title="Google Map"
        ></iframe>
  );
}
