import React, { useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PopupModal from '../components/PopupModal'; // Zorg ervoor dat je dit pad correct aanpast

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('customer'); // Default user type
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check for super user credentials
    if (username === 'AlmeriaTilburg' && password === 'YoussraAkdimi') {
      onLogin(true); // Simulate successful login
      localStorage.setItem('loggedIn', 'true'); // Save login status
      setShowModal(true); // Show modal

      // Close the modal and navigate to the dashboard
      setTimeout(() => {
        setShowModal(false);
        navigate('/dashboard');
      }, 5000); // Wait 2 seconds before navigating
    } else {
      setErrorMessage('Ongeldige inloggegevens. Probeer het opnieuw.');
      setUsername('');
      setPassword('');
    }
  };

  const handleCloseModal = () => setShowModal(false); // Function to close modal

  return (
    <div>
      <Card style={{ maxWidth: '400px', margin: 'auto', marginTop: '50px' }}>
        <Card.Body>
          <h2 className="text-center">Login</h2>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formUserType">
              <Form.Label>Inloggen als: </Form.Label>
              <Form.Check
                type="radio"
                label="Klant"
                name="userType"
                value="customer"
                checked={userType === 'customer'}
                onChange={(e) => setUserType(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="Medewerker"
                name="userType"
                value="medewerker"
                checked={userType === 'medewerker'}
                onChange={(e) => setUserType(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formUsername">
              <Form.Label>Gebruikersnaam</Form.Label>
              <Form.Control
                type="text"
                placeholder="Gebruikersnaam"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Wachtwoord</Form.Label>
              <Form.Control
                type="password"
                placeholder="Wachtwoord"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type="submit">
                Login
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      
      {/* Popup modal for successful login */}
      <PopupModal 
        show={showModal} 
        handleClose={handleCloseModal} 
        message={`Login succesvol! Welkom, ${username}.`} 
      />
    </div>
  );
};

export default Login;
