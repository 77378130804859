import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cart from './components/Cart';
import AboutUs from './pages/Aboutus';
import Home from './pages/Home';
import MenuPage from './pages/MenuPage';
import OrderPage from './pages/OrderPage';
import TermsPage from './pages/Terms';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/LoginPage';
import TopNavbar from './components/TopNavbar';
import CartSidebar from './components/CartSideBar';
import ProductModal from './components/ProductModal';
import SuccessPage from './pages/SuccessPage';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);

  const handleLogin = (status) => {
    setIsLoggedIn(status);
    localStorage.setItem('loggedIn', status);
  };

  const handleLogout = () => {
    localStorage.removeItem('loggedIn');
    setIsLoggedIn(false);
  };

  const toggleCart = () => {
    setShowCart(!showCart);
  };

  const addToCart = (item) => {
    fetch(`https://web-production-1f038.up.railway.app/api/products/${item.id}/options`)
      .then(response => response.json())
      .then(data => {
        if (Object.keys(data).length > 0) {
          setSelectedProduct(item);
          setShowModal(true);
        } else {
          setCart((prevCart) => {
            const cartItemId = `${item.id}-${Date.now()}`; // Create a unique ID
            console.log("id:", {cartItemId});
            return [...prevCart, { ...item, cartItemId }]; // Add unique cartItemId to the cart item
          });
          setShowCart(true);
        }
      })
      .catch(error => console.error('Error fetching product options:', error));
  };

  const handleConfirmOrder = (selectedOptions, totalPrice) => {
    setCart((prevCart) => {
      const cartItemId = `${selectedProduct.id}-${Date.now()}`; // Unique ID for the ordered item
      return [...prevCart, { ...selectedProduct, options: selectedOptions, cartItemId: cartItemId, price: totalPrice }];
    });
    setShowModal(false);
    setShowCart(true);
  };

  // Updated removeFromCart function
  const removeFromCart = (cartItemId) => {
    setCart((prevCart) => {
      return prevCart.filter((cartItem) => cartItem.cartItemId !== cartItemId); // Remove based on unique cartItemId
    });
  };

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <div className="App">
      <BrowserRouter>
        <TopNavbar
          toggleCart={toggleCart}
          isLoggedIn={isLoggedIn}
          onLogout={handleLogout}
        />
        <CartSidebar
          showCart={showCart}
          cart={cart}
          toggleCart={toggleCart}
          removeFromCart={removeFromCart} // Pass the removeFromCart function
        />
        <ProductModal
          showModal={showModal}
          handleCloseModal={() => setShowModal(false)}
          selectedProduct={selectedProduct}
          onConfirm={handleConfirmOrder}
        />
        <Routes>
          <Route path="/" element={<Home addToCart={addToCart} cart={cart} />} />
          <Route path="cart" element={<Cart cart={cart} removeFromCart={removeFromCart} />} />
          <Route path="contact" element={<AboutUs cart={cart} />} />
          <Route path="menu" element={<MenuPage cart={cart} addToCart={addToCart} toggleCart={toggleCart} />} />
          <Route path="order" element={<OrderPage cart={cart} toggleCart={toggleCart} removeFromCart={removeFromCart} setCart={setCart} />} />
          <Route path="algemene-voorwaarde" element={<TermsPage />} />
          <Route path="dashboard" element={isLoggedIn ? <Dashboard /> : <LoginPage onLogin={handleLogin} />} />
          <Route path="login" element={<LoginPage onLogin={handleLogin} />} />
          <Route path="success" element={<SuccessPage/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
