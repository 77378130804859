import React from 'react';
import { Card, ListGroup, Tab, Tabs } from 'react-bootstrap';

const openingTimes = [
  { day: 'Maandag', pickupHours: '16:00 - 22:00', deliveryHours: '16:00 - 20:00' },
  { day: 'Dinsdag', pickupHours: '16:00 - 22:00', deliveryHours: '16:00 - 20:00' },
  { day: 'Woensdag', pickupHours: 'Gesloten', deliveryHours: 'Gesloten' },
  { day: 'Donderdag', pickupHours: '16:00 - 22:00', deliveryHours: '16:00 - 20:00' },
  { day: 'Vrijdag', pickupHours: '16:00 - 22:00', deliveryHours: '16:00 - 22:00' },
  { day: 'Zaterdag', pickupHours: '16:00 - 22:00', deliveryHours: '16:00 - 22:00' },
  { day: 'Zondag', pickupHours: '16:00 - 22:00', deliveryHours: '16:00 - 22:00' },
];

const OpeningTimes = () => {
  return (
    <Card>
      <Card.Header>Openingstijden</Card.Header>
      <Tabs defaultActiveKey="pickup" id="opening-times-tabs">
        <Tab eventKey="pickup" title="Afhalen">
          <Card.Body>
            <ListGroup variant="flush">
              {openingTimes.map((time, index) => (
                <ListGroup.Item key={index} className="d-flex justify-content-between">
                  <span><strong>{time.day}</strong></span>
                  <span>{time.pickupHours}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Tab>
        <Tab eventKey="delivery" title="Bezorging">
          <Card.Body>
            <ListGroup variant="flush">
              {openingTimes.map((time, index) => (
                <ListGroup.Item key={index} className="d-flex justify-content-between">
                  <span><strong>{time.day}</strong></span>
                  <span>{time.deliveryHours}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Tab>
      </Tabs>
    </Card>
  );
};

export default OpeningTimes;
