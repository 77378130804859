import React from 'react';
import { Card } from 'react-bootstrap';

const address = 'Oerlesestraat 171, 5025 DB Tilburg';

const Address = () => {
    return (
        <Card>
              <Card.Body>
                <Card.Title>Adres</Card.Title>
                <Card.Text>{address}</Card.Text>
              </Card.Body>
        </Card>
    );
};

export default Address;
