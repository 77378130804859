import React from 'react';
import { Offcanvas, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CartSidebar = ({ showCart, cart, toggleCart, removeFromCart}) => {
  const totalPrice = cart.reduce((acc, item) => acc + item.price, 0); // No quantity handling

  return (
    <Offcanvas show={showCart} onHide={toggleCart} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Winkelwagen</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {cart.length === 0 ? (
          <p>Je winkelwagen is leeg.</p>
        ) : (
          <div>
            {cart.map((item) => (
              <Card key={item.cartItemId} className="mb-3">
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{`${item.category} - ${item.name}`}</div>
                    <div>
                      <span className="me-2">{`€${item.price.toFixed(2)}`}</span>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => removeFromCart(item.cartItemId)} // Call removeFromCart directly with item id

                      >
                        Verwijderen
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))}
            <hr />
            <div className="text-end">
              <strong>Totaal: €{totalPrice.toFixed(2)}</strong>
            </div>
            <Link to="/order" className="d-flex justify-content-center align-items-center m-5">
              <Button variant="primary" onClick={toggleCart} block="true">
                Bestelling Plaatsen
              </Button>
            </Link>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CartSidebar;
