// src/pages/SuccessPage.js
import React from 'react';
import { Container, Card } from 'react-bootstrap';

const SuccessPage = () => {
  return (
    <Container className="p-3">
      <Card>
        <Card.Header>Betaling geslaagd</Card.Header>
        <Card.Body>
          <h5>Bedankt voor uw bestelling!</h5>
          <p>Uw betaling is succesvol verwerkt. U ontvangt een bevestigingsmail.</p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SuccessPage;
