import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useState, useEffect } from 'react';

const MostSold = ({addToCart}) => {
    const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://web-production-1f038.up.railway.app/api/meest-verkocht');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        // Transform the data to match the desired format
        const formattedProducts = data.map((product, index) => ({
          id: product.product_id,
          category: product.category,
          name: product.name,
          price: product.price,
          description: product.description,
          image: product.image
        }));
        setProducts(formattedProducts);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, []); // Empty dependency array means this effect will run once after the component mounts
  // Hardcoded list of most sold products with images
  const mostSoldProducts = products;

  // Selecting the top 8 most sold products
  const topProducts = mostSoldProducts.slice(0, 8);

  return (
    <Card className='mt-4 mb-4'>
        <Card.Header>Meest Verkocht</Card.Header>
        <Row xs={1} md={2} lg={4} className="g-4 p-4">
            {topProducts.map((product, index) => (
            <Col key={index}>
                <Card >
                    <Card.Img style={{ height: '150px', objectFit: 'cover' }} variant="top" src={process.env.PUBLIC_URL + '/images/' + product.image} loading="lazy" />
                    <Card.Body>
                        <Card.Title>{product.name}</Card.Title>
                        <Card.Text>Prijs: €{product.price.toFixed(2)}</Card.Text>
                        <Button variant="success" onClick={() => addToCart(product)}>Bestel nu</Button>
                    </Card.Body>
                </Card>
            </Col>
            ))}
        </Row>
        <div className="text-center mt-2 mb-4">
            <Link to="/menu">
            <Button variant="primary">Bekijk het menu</Button>
            </Link>
        </div>
    </Card>
  );
};

export default MostSold;
