import React from 'react';
import { Card } from 'react-bootstrap';

const TermsPage = () => {
  return (
    <div>
        <Card className='m-5 p-5'>
            <h1>Algemene Voorwaarden Almeria Pizzeria</h1>
            <h2>Algemeen</h2>
            <p>1.1. Deze algemene voorwaarden zijn van toepassing op alle bestellingen die worden geplaatst op de website van Almeria Pizzeria voor bezorging van voedsel.</p>
            <p>1.2. Door een bestelling te plaatsen op onze website, stemt de klant in met deze algemene voorwaarden.</p>

            <h2>Bestellingen</h2>
            <p>2.1. Bestellingen kunnen alleen worden geplaatst via de website van Almeria Pizzeria.</p>
            <p>2.2. Klanten zijn verantwoordelijk voor het verstrekken van juiste en volledige informatie bij het plaatsen van een bestelling, inclusief contactgegevens en afleveradres.</p>
            <p>2.3. Almeria Pizzeria behoudt zich het recht voor om bestellingen te weigeren of te annuleren indien deze in strijd zijn met deze algemene voorwaarden of om andere redenen die naar eigen goeddunken worden bepaald.</p>

            <h2>Prijs en Betaling</h2>
            <p>3.1. De prijzen van de producten zijn vermeld op de website van Almeria Pizzeria en zijn inclusief btw.</p>
            <p>3.2. Betaling dient te geschieden op het moment van bestelling via de beschikbare betaalmethoden op de website.</p>

            <h2>Bezorging</h2>
            <p>4.1. Almeria Pizzeria streeft ernaar om bestellingen binnen een redelijke termijn te bezorgen, maar kan geen exacte levertijden garanderen.</p>
            <p>4.2. Klanten dienen beschikbaar te zijn op het opgegeven afleveradres om de bestelling in ontvangst te nemen op het moment van bezorging.</p>
            <p>4.3. Indien de klant niet aanwezig is op het afleveradres op het moment van bezorging, kan een extra bezorgkost in rekening worden gebracht bij een nieuwe bezorgpoging.</p>

            <h2>Annulering en Retournering</h2>
            <p>5.1. Annulering van een bestelling is alleen mogelijk vóór de verzending. Na verzending is annulering niet meer mogelijk.</p>
            <p>5.2. Retournering van producten is alleen mogelijk indien de producten niet voldoen aan de verwachtingen van de klant of indien er sprake is van een fout bij de bezorging.</p>

            <h2>Aansprakelijkheid</h2>
            <p>6.1. Almeria Pizzeria is niet aansprakelijk voor enige schade of verlies als gevolg van het gebruik van de website of het bestellen van producten via de website.</p>
            <p>6.2. De aansprakelijkheid van Almeria Pizzeria is beperkt tot het bedrag van de bestelling.</p>

            <h2>Wijzigingen</h2>
            <p>7.1. Almeria Pizzeria behoudt zich het recht voor om deze algemene voorwaarden op elk moment te wijzigen. Gewijzigde voorwaarden zijn van toepassing op alle bestellingen die worden geplaatst na de datum van wijziging.</p>
            <p>Door het plaatsen van een bestelling op de website van Almeria Pizzeria, verklaart de klant deze algemene voorwaarden te hebben gelezen, begrepen en ermee akkoord te gaan.</p>
            <p>Datum van laatste wijziging: [06-02-2024]</p>
        </Card>
    </div>
  );
};

export default TermsPage;
