import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';

const OrderModal = ({ isOpen, onRequestClose, onSubmit, cartItems, totalPrice }) => {
  const [formData, setFormData] = useState({
    name: '',
    street: '',
    houseNumber: '',
    postcode: '',
    phoneNumber: '',
    email: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare order data to send to the backend
    const orderData = {
      cartItems,
      totalPrice,
      customerDetails: formData,
    };
    onSubmit(orderData); // Call the function passed from OrderPage to handle order submission
    onRequestClose(); // Close the modal after submitting
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose}>
      <Modal.Header closeButton>
        <Modal.Title>Plaats Bestelling</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Naam:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Vul uw naam in"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formAddress">
            <Form.Label>Adres:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Straat en huisnummer"
              name="street"
              value={formData.street}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPostcode">
            <Form.Label>Postcode:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Vul uw postcode in"
              name="postcode"
              value={formData.postcode}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formHouseNumber">
            <Form.Label>Huisnummer:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Vul uw huisnummer in"
              name="houseNumber"
              value={formData.houseNumber}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPhoneNumber">
            <Form.Label>Telefoonnummer:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Vul uw telefoonnummer in"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Vul uw email in"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Plaats Bestelling
          </Button>
          <Button variant="secondary" onClick={onRequestClose} className="ms-2">
            Sluiten
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default OrderModal;
