import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Alert, Form } from 'react-bootstrap';
import TopPage from '../components/TopPage';
import OrderModal from '../components/OrderModal';
import { Link } from 'react-router-dom';

const OrderPage = ({ cart, toggleCart, removeFromCart, setCart }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Effect to save cart to local storage
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const totalPrice = cart.reduce((acc, item) => acc + item.price, 0); // Summing prices directly since quantity is removed

  // Handle when the order is placed from the modal
  const handleOrderSubmit = async (orderData) => {
    setIsSubmitting(true);

    try {
      const response = await fetch('https://web-production-1f038.up.railway.app/api/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });

      const result = await response.json();

      if (response.ok) {
        // Redirect to the checkout URL
        window.location.href = result.payment_url; // Redirect to payment URL

        // Optionally, you can also show an alert here
        setAlertVariant('success');
        setAlertMessage('Order successfully placed!');
        setShowAlert(true);
        setCart([]); // Clear cart after successful order
      } else {
        setAlertVariant('danger');
        setAlertMessage(result.error || 'Failed to place order. Please try again.');
        setShowAlert(true);
      }
    } catch (error) {
      setAlertVariant('danger');
      setAlertMessage('Error while placing order. Please try again.');
      setShowAlert(true);
    } finally {
      setIsSubmitting(false);
      setIsModalOpen(false); // Close the modal after submission
    }
  };

  // Currency formatting helper
  const formatCurrency = (amount) => `€${amount.toFixed(2)}`;

  return (
    <div>
      <TopPage pageTitle="Bestellen" />

      <Container className="p-3">
        {showAlert && (
          <Alert
            variant={alertVariant}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            {alertMessage}
          </Alert>
        )}

        <Card>
          <Card.Header>Bestelling</Card.Header>
          {cart.length === 0 ? (
            <p>Winkelwagen is leeg.</p>
          ) : (
            <div>
              {cart.map((item) => (
                <Card key={item.cartItemId} className="mb-3">
                  <Card.Body>
                    <div>
                      {`${item.category} - ${item.name}${item.option ? ` (${item.option})` : ''}`}
                    </div>
                    <div>{`Prijs: ${formatCurrency(item.price)}`}</div>
                    <Button
                      variant="outline-danger"
                      onClick={() => removeFromCart(item.cartItemId)} // Call removeFromCart directly with item id
                      style={{ marginTop: '10px' }}
                    >
                      Verwijder
                    </Button>
                  </Card.Body>
                </Card>
              ))}
              <hr />
              <div className="text-end">
                <strong>Totaal: {formatCurrency(totalPrice)}</strong>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <Form.Group controlId="termsCheckbox" className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label={
                      <span>
                        Ik ga akkoord met de{' '}
                        <Link to="/algemene-voorwaarde">algemene voorwaarden</Link>
                      </span>
                    }
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                </Form.Group>
              </div>
              <div className="d-flex justify-content-center align-items-center mb-4">
                <Button
                  type="button" // Prevent default form submission
                  variant="primary"
                  onClick={() => setIsModalOpen(true)} // Open modal
                  disabled={!termsAccepted || isSubmitting}
                >
                  {isSubmitting ? 'Plaatsing...' : 'Plaats bestelling'}
                </Button>
              </div>
            </div>
          )}
        </Card>
      </Container>

      <OrderModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onSubmit={handleOrderSubmit}
        cartItems={cart}
        totalPrice={totalPrice}
      />
    </div>
  );
};

export default OrderPage;
