import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'react-bootstrap';

const TopNavbar = ({ toggleCart, isLoggedIn, onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    if (onLogout) {
      onLogout(); // Roep de logout functie aan
    }
    navigate('/'); // Navigeer naar de homepagina
  };

  return (
    <Navbar expand="lg" style={{ backgroundColor: '#92C7C7' }}>
      <Container>
        <Navbar.Brand href="/">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" style={{ height: '50px', marginRight: '10px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/menu">Menu</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            {!isLoggedIn ? (
              <Nav.Link href="/login">Inloggen</Nav.Link>
            ) : (
              <>
                <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                <Nav.Link onClick={handleLogout} style={{ cursor: 'pointer' }}>Uitloggen</Nav.Link>
              </>
            )}
          </Nav>
          <Button variant="success" onClick={toggleCart}>
            Winkelwagen 
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
