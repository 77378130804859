// Cart.jsx
import React from 'react';

const Cart = () => {
  // Display the items in the cart and order options

  return (
    <div>
        <h1>Cart</h1>
      {/* Display the cart items */}
    </div>
  );
};

export default Cart;
