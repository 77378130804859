// src/pages/Home.jsx
import React from 'react';
import CartSidebar from '../components/CartSideBar';
import TopPage from '../components/TopPage';
import OpeningTimes from '../components/OpeningTimes';
import MostSold from '../components/MostSold';

const Home = ({ showCart, toggleCart, addToCart, cart, removeFromCart }) => {
  return (
    <div>
      <TopPage pageTitle={'Almeria, Alles in een Pizzeria!'} />
      <div className="container mt-5">
        <OpeningTimes />
        <MostSold addToCart={addToCart} />
      </div>
      <CartSidebar showCart={showCart} toggleCart={toggleCart} cart={cart} removeFromCart={removeFromCart} />
    </div>
  );
};

export default Home;
